._hero {
    height: 100vh;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(6, 12, 34, 0.8);
    }

    ._container, ._content {
        height: 100%;
        position: relative;
    }

    ._context {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        ._heading {
            font-size: 56px;
            color: #fff;
            margin-bottom: 1rem;

            span {
                color: #f82249;
            }
        }

        ._sub_heading {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 1rem;
            color: #fff;
        }

        @media only screen and (max-width: 768px) {
            ._heading {
                font-size: 32px;
            }

            ._sub_heading {
                font-size: 15px;
            }
        }
    }

    a {
        display: inline-block;
        margin-bottom: 2.5rem;
        
        ._play_button {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 94px;
            width: 94px;
            border-radius: 50%;
            background: radial-gradient(#f82249 50%, rgba(101, 111, 150, 0.15) 52%);
            overflow: hidden;

            &::before {
                content: "";
                position: absolute;
                width: 100px;
                height: 100px;
                animation-delay: 0s;
                animation: pulsate-btn 2s;
                animation-direction: forwards;
                animation-iteration-count: infinite;
                animation-timing-function: steps;
                opacity: 1;
                border-radius: 50%;
                border: 2px solid rgba(163, 163, 163, 0.4);
                top: -5%;
                left: -5%;
                background: rgba(198, 16, 0, 0);

                @keyframes pulsate-btn {
                    0% {
                      transform: scale(0.6, 0.6);
                      opacity: 1;
                    }
                  
                    100% {
                      transform: scale(1, 1);
                      opacity: 0;
                    }
                }
            }

            &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-40%) translateY(-50%);
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 15px solid #fff;
                z-index: 100;
                transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            &:hover {
                &::after {
                    border-left: 15px solid #f82249;
                    transform: scale(20);
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-40%) translateY(-50%);
                    width: 0;
                    height: 0;
                    border: none;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 15px solid #fff;
                    z-index: 200;
                    -webkit-animation: none;
                    animation: none;
                    border-radius: 0;
                }
            }
        }
    }

    ._about_the_event_btn {
        display: inline-block;
        height: 42px;
        line-height: 42px;
        padding: 0 36px;
        box-shadow: 0 0 0 2px #f82249;
        color: #fff;
        border-radius: 30px;
    }
}

